<template>
    <BlogPostLayout :tags="tags" :meta="meta">
        <template v-slot:title>BEAM me up, Scotty: A short talk with Saša Jurić</template>

        <template v-slot:excerpt>Saša is a household name in the Elixir community, and his book, Elixir in
            Action, is a staple we at Superology already use for our backend developer onboarding. Apart from
            sharing his vast knowledge and giving lectures at Superology, he's helping us improve our backend
            code, write our code style, and develop new libraries for Kafka and Protobuf. We wanted to pick his
            brain about his work and how he sees Superology in terms of technical development and growth.</template>

        <template v-slot:content>
            <img class="blog-post-image" src="../../../assets/blog/images/sasa-juric.jpg" alt="Saša Jurić">

            <h2>Who is Saša Jurić? Could you tell us a bit more about your career? What would be your expertise?</h2>

            <p>I'm a software developer, mentor, and consultant. I wrote my first "Hello World" some 30 years ago, and I've been developing software as a full-time professional for the past 20 years. I've worked on various kinds of projects, desktop apps, frontends, and backends, using different technologies, such as C++, C#, Ruby, and JavaScript. For the past 10 years, my main focus has been on BEAM programming languages, primarily Elixir. More recently, I've been operating as an independent Elixir mentor/consultant, helping teams adopt the language and use it in production. I'm the author of the book "Elixir in Action" and an occasional public speaker at international conferences.</p>

            <h2>What are you working on in Superology?</h2>

            <p>I'm collaborating with the Superology backend team in a couple of different ways. First, I've been doing a series of talks on various BEAM-related topics. In addition, I've been working with the team on a company-wide style guide. In a more traditional developer role, I helped the team address some long-standing technical pain points, such as working with the Google Protobuf format and interacting with Apache Kafka. The latter was particularly interesting, as it led to writing a completely new Elixir Kafka client, which is internally implemented as a wrapper around Java's Kafka library.</p>

            <h2>How could this impact our growth?</h2>

            <p>The internal talks aim to expand the team's knowledge about the BEAM, help them avoid some common pitfalls of the technology, and allow them to manage the production with more confidence. The style guide increases the code consistency, which in turn makes it easier for people to work with the code they didn't originally write, shifting the code ownership from individuals to the entire team.</p>

            <p>Finally, the mentioned technical improvements resolve some issues that occurred in day-to-day development, while the new Kafka client unlocks some features that were previously not available to BEAM developers. All of these things should make the people more efficient and make it easier for the team to grow.</p>

            <h2>How are you getting on with the people you’re working with?</h2>

            <p>I've been enjoying working with everyone so far. People are smart, but beyond that, also curious, open-minded, and overall nice. They are exactly the kind of people I like working with.</p>

            <h2>In this regard, what do you see as our strengths? Or weaknesses, for that matter?</h2>

            <p>In my view, the biggest strength of any company, and Superology is no exception, are its people. And as I've just mentioned, I think the people on the team are great.</p>

            <p>From a technical point of view, it's impressive to see how the team managed to implement a high-throughput, low-latency backend at scale using technology they didn't work with much previously.</p>

            <p>When it comes to "weaknesses", I don't really like to think in such terms. Instead, I prefer to think about the potential for improvement. I've been providing pure technical services, and while I see a few areas where some improvement could be made, such as the approach to writing tests, overall, I think that things are looking pretty good.</p>
        </template>
    </BlogPostLayout>
</template>

<script>
import blogMetadata from '@/util/blogMetadata'
import BlogPostLayout from '@/components/blog/BlogPostLayout.vue'
import authors from '@/views/blog/posts/authors'

export default {
    name: 'BeamMeUpScottySasaJuric',

    metaInfo: blogMetadata.beamMeUpScottySasaJuric,

    props: {},

    data: function () {
        return {
            tags: ['Engineering', 'Backend', 'BEAM', 'Elixir', 'Kafka', 'Protobuf'],
            meta: {
                authors: [authors.vatroslavMilos],
                publishedDate: '21 nov 2022'
            }
        }
    },

    created() {

    },

    components: {BlogPostLayout},

    computed: {},

    methods: {}
}
</script>
